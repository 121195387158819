import React from "react";
import PropTypes from "prop-types";
import classes from "../courses.module.scss";
import { styleVariables } from "../../../theme/styleVariables";
import styled, { ThemeProvider } from "styled-components";

const Ul = styled.ul`
  list-style: none;
  margin-top: 44px;

  .list {
    &__item {
      display: flex;
      align-items: flex-start;
      margin-top: 25px;
    }

    &__img {
      width: 45px;
      height: 45px;
      margin-right: 30px;
    }

    &__text {
      font-size: ${(props) => props.theme.text.description};
      line-height: ${(props) => props.theme.text.descLineHide};

      letter-spacing: -0.02em;
      color: ${(props) => props.theme.colors.text};

      &_marked {
        font-weight: bold;
        color: ${(props) => props.theme.colors.markedText};
      }
    }
  }

  @media (max-width: 1399px) {
    .list {
      &__text {
        font-size: ${(props) => props.theme.text.description1399Max};
      }
    }
  }

  @media (max-width: 1099px) {
    .list {
      &__text {
        font-size: ${(props) => props.theme.text.description1099Max};
        line-height: ${(props) => props.theme.text.descLineHide1099Max};
      }

      &__img {
        width: 30px;
        height: 30px;
        margin-right: 15px;
      }
    }
  }
`;

export const ListOfRequirements = ({ list }) => {
  return (
    <ThemeProvider theme={styleVariables}>
      <Ul>
        {list.map((item) => (
          <li className="list__item" key={item.id}>
            <img
              src={require(`../../../images/icons/${item.img}`)}
              alt={item.alt}
              className="list__img"
            />

            <p className="list__text">
              {item.markedFirst ? (
                <>
                  <span className="list__text_marked">{item.textMarked}</span>
                  {item.text}
                </>
              ) : (
                <>
                  {item.text}
                  <span className="list__text_marked">{item.textMarked}</span>
                </>
              )}
            </p>
          </li>
        ))}
      </Ul>
    </ThemeProvider>
  );
};

ListOfRequirements.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
};
