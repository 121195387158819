export const styleVariables = {
  colors: {
    primaryColor: '#3491C8',
    text: '#100a2e',
    markedText: '#09051f'
  },
  text: {
    description: '24px',
    description1399Max: '20px',
    description1099Max: '16px',
    description400Max: '14px',

    descLineHide: '34px',
    descLineHide1099Max: '22px',

    titleLineHide: '59px',
    titleLineHide1099Max: '45px',
    titleLineHide767Max: '29px',

    pageTitle: '40px',
    pageTitle1099Max: '28px',
    pageTitle400Max: '26px',

    title: '48px',
    title1099Max: '38px',
    title767Max: '24px',
  }
}