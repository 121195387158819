import React from "react";
import PropTypes from "prop-types";
import classes from "../courses.module.scss";
import { styleVariables } from "../../../theme/styleVariables";
import styled, { ThemeProvider } from "styled-components";
import {downloadFile} from "../../../utils/download-file";

const Section = styled.section`
  position: relative;
  background-image: ${`url(${require("../../../images/coursesBgMiddle.png")})`};
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 auto;
  max-width: 2300px;
  padding: 235px 0 180px;

  .recording {
    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__title {
      max-width: 50%;
      font-weight: bold;
      font-size: ${(props) => props.theme.text.title};
      line-height: ${(props) => props.theme.text.titleLineHide};
      text-align: center;

      color: ${(props) => props.theme.colors.primaryColor};
    }

    &__blocks {
      display: flex;
      justify-content: center;
    }

    &__wrapper {
      margin: 70px 10px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #fff;
      border: 2px solid #4e7baa;
      border-radius: 25px;
      padding: 41px 0 60px;
      width: 530px;
    }

    &__img {
      width: 65%;
    }

    &__button {
      margin-top: 55px;
      font-size: 18px;
      letter-spacing: 0.1em;
    }

    &__text {
      margin-top: 34px;
      font-size: ${(props) => props.theme.text.description};
      line-height: ${(props) => props.theme.text.descLineHide};
      letter-spacing: -0.02em;

      color: ${(props) => props.theme.colors.text};
    }
  }

  @media (min-width: 2300px) {
    &::after {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      background-image: ${`url(${require("../../../images/coursesBgMiddle.png")})`};
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      transform: scale(-1, 1);
      top: 25px;
      left: 99%;
    }

    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      background-image: ${`url(${require("../../../images/coursesBgMiddle.png")})`};
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      transform: scale(-1, 1);
      right: 92%;
      top: 16px;
    }
  }

  @media (max-width: 1599px) {
    .recording {
      &__title {
        max-width: 60%;
      }
    }
  }

  @media (max-width: 1399px) {
    .recording {
      &__title {
        max-width: 75%;
      }

      &__text {
        font-size: ${(props) => props.theme.text.description1399Max};
      }
    }
  }

  @media (max-width: 1099px) {
    padding: 150px 0 180px;

    .recording {
      &__title {
        max-width: 80%;
        font-size: ${(props) => props.theme.text.title1099Max};
        line-height: ${(props) => props.theme.text.titleLineHide1099Max};
      }

      &__text {
        width: 70%;
        text-align: center;
        font-size: ${(props) => props.theme.text.description1099Max};
      }

      &__blocks {
        align-items: center;
        margin-top: 22px;
        flex-direction: column;
      }

      &__wrapper {
        margin: 18px 0 0;
        width: 400px;
      }

      &__button {
        margin-top: 30px;
      }
    }
  }

  @media (max-width: 767px) {
    .recording {
      &__title {
        max-width: 100%;
        font-size: ${(props) => props.theme.text.title767Max};
        line-height: ${(props) => props.theme.text.titleLineHide767Max};
      }

      &__text {
        width: 100%;
        line-height: 22px;
      }

      &__blocks {
        width: 100%;
      }

      &__wrapper {
        width: 80%;
        padding: 30px 0 40px;
      }
    }
  }

  @media (max-width: 600px) {
    .recording {
      &__wrapper {
        width: 100%;
      }

      &__button {
        width: 80%;
        font-size: 14px;
      }
    }
  }
  @media (max-width: 400px) {
    .recording {
      &__title {
        font-size: 22px;
      }
    }
  }
`;

export const Recording = ({ recording, toggleContactForm }) => {

  return (
    <ThemeProvider theme={styleVariables}>
      <Section>
        <div className={`${classes.container} recording__container`}>
          <h2 className="recording__title">{recording.title}</h2>

          <div className="recording__blocks">
            {recording.blocks.map((block) => (
              <div className="recording__wrapper" key={block.id}>
                <img
                  src={require(`../../../images/images/${block.img}`)}
                  alt={block.alt}
                  className="recording__img"
                />

                <button 
                  className={`${classes.button} recording__button`}
                  onClick={() => {
                    if ( block.button.title === 'Зареєструватися' ) {
                      return toggleContactForm(true)
                    } else {
                      return downloadFile(block.button.file, block.button.fileName)
                    }
                  }}
                >
                  { block.button.title }
                </button>
              </div>
            ))}
          </div>

          <p className="recording__text">{recording.text}</p>
        </div>
      </Section>
    </ThemeProvider>
  );
};

Recording.propTypes = {
  recording: PropTypes.shape({
    title: PropTypes.string,
    blocks: PropTypes.arrayOf(PropTypes.object),
    text: PropTypes.string,
  }).isRequired,
};
