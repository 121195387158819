import React from 'react';
import developerPDF from '../../../Files/MS Dynamics 365 Developer.pdf';
import testTask from '../../../Files/TestTask-Template.Dynamics-Education-1.zip';
import developerAltImg from "../../../images/images/Developer_ios.png";

export const developer = {
  introducePage: {
    title: "Курс MS Dynamics 365 Developer",
    description:
      "Якщо ти маєш теоретичну підготовку в сфері IT та навички програмування на C# .NET у тебе є можливість почати свою кар’єру в якості MS Dynamics CRM Developer",
    button: {
      text: "Програма навчання",
      file: developerPDF,
      fileName: 'MS Dynamics 365 Developer.pdf',
    },
    img:
      "https://dynamicsnetuastorageprod.blob.core.windows.net/animations/Best_Developer.webm",
    altImg: developerAltImg
  },

  requirementsPage: {
    title: "Основні вимоги:",
    list: [
      {
        id: 1,
        img: "code.svg",
        alt: "coding",
        text: ", навчатися новому та працювати",
        textMarked: "Бажання програмувати",
        markedFirst: true,
      },
      {
        id: 2,
        img: "js.svg",
        alt: "JS",
        text: "Розуміння основ ",
        textMarked: "JavaScript",
        markedFirst: false,
      },
      {
        id: 3,
        img: "programming.svg",
        alt: "C Sharp",
        text: "Початковий досвід програмування ",
        textMarked: "на С# .NET",
        markedFirst: false,
      },
      {
        id: 4,
        img: "diploma.svg",
        alt: "diploma",
        text: "бакалавра або магістра в сфері IT",
        textMarked: "Ступінь ",
        markedFirst: true,
      },
      {
        id: 5,
        img: "language.svg",
        alt: "language",
        text: " – Intermediate та вище",
        textMarked: "Англійська мова",
        markedFirst: true,
      },
    ],
    img: "Ill_Certificate1.png",
  },

  recordingPage: {
    title: "Для того, щоб записатися на курс необхідно:",
    blocks: [
      {
        id: 1,
        img: "technicalTask.png",
        alt: "technicalTask",
        button: {
          title: "Виконати ТЗ",
          file: testTask,
          fileName: 'TestTask-Template.Dynamics-Education-1.zip',
        },
      },
      {
        id: 2,
        img: "recording.png",
        alt: "recording",
        button: {
          title: "Зареєструватися",
          link: "",
        },
      },
    ],

    text: `Протягом 5-ти днів з тобою зв'яжуться, чекай на дзвінок та не забувай перевіряти свій email.`,
  },

  advantagesPage: {
    title: "Наші переваги:",
    advantages: [
      {
        id: 1,
        img: "time.png",
        alt: "time",
        title: "Тривалість навчання — 6 тижнів",
        text:
          "Навчаємо розробників дистанційно та за індивідуальним графіком. То ж, запрошуємо долучитися до курсу охочих з будь-якого куточка України у зручний для них час!",
        order: 0,
      },
      {
        id: 2,
        img: "group.png",
        alt: "group of people",
        title: "Навчальна група до 10 чоловік",
        text:
          <>Кількість учнів на курсі обмежена, так як ми орієнтуємося на працевлаштування всіх успішних випускників курсу при компанії <b><a style={{ textDecoration: 'none' }} href='https://uds.systems/'>UDS Systems</a></b> на позицію Junior Dynamics 365 Developer.</>,
        order: 1,
      },
      {
        id: 3,
        img: "bonus.png",
        alt: "Bonus",
        title: "Бонус за проходження курсу",
        text:
          "Кожен з учнів даного напрямку має можливість не тільки безкоштовно пройти курс та отримати знання по платформі Microsoft Dynamics 365, але й отримати грошовий бонус за успішне проходження курсу та оффер.",
        order: 0,
      },
    ],
  },
};
