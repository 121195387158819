export const aboutStart = {
  title: 'Заповни форму і ми повідомимо тебе про початок курсу',
  message: true,
  template_id: 'template_hGxmtURS',
  buttonText: 'Відправити',
};

export const consultantForm = {
  title: 'Реєстраційна форма на курс MS Dynamics 365 Consultant',
  upload: true,
  template_id: 'template_bjh0yfh',
  buttonText: 'Зареєструватися',
}

export const developerForm = {
  title: 'Реєстраційна форма на курс MS Dynamics 365 Developer',
  upload: true,
  template_id: 'template_2ge28jo',
  description: `Обов'язково вкажи у резюме посилання на хмарне сховище з виконаним ТЗ (Github, Google Drive, etc.)`,
  buttonText: 'Зареєструватися',
}
