import React from "react";
import PropTypes from "prop-types";
import classes from "../courses.module.scss";
import { styleVariables } from "../../../theme/styleVariables";
import styled, { ThemeProvider } from "styled-components";

const Section = styled.section`
  padding-top: 50px;

  .advantages {
    &__title {
      font-weight: bold;
      font-size: ${(props) => props.theme.text.title};
      text-align: center;

      color: ${(props) => props.theme.colors.primaryColor};
    }

    &__item {
      display: flex;
      margin-top: 15px;
      align-items: center;
      justify-content: space-between;
    }

    &__img {
      height: 450px;
    }

    &__subtitle {
      font-weight: bold;
      font-size: ${(props) => props.theme.text.description};
      line-height: ${(props) => props.theme.text.descLineHide};
      color: ${(props) => props.theme.colors.primaryColor};
    }

    &__wrapper {
      flex-basis: 55%;
    }

    &__text {
      margin-top: 30px;
      font-size: 18px;
      line-height: 25px;
      color: ${(props) => props.theme.colors.text};
    }
  }

  @media (max-width: 1599px) {
    .advantages {
      &__img {
        height: 400px;
      }
    }
  }

  @media (max-width: 1599px) {
    .advantages {
      &__img {
        height: 350px;
      }
    }
  }

  @media (max-width: 1099px) {
    padding-top: 0;
    .advantages {
      &__title {
        font-size: ${(props) => props.theme.text.title1099Max};
      }

      &__img {
        height: 250px;
      }

      &__text {
        margin-top: 15px;
        font-size: 13px;
        line-height: 22px;
      }

      &__subtitle {
        font-size: 20px;
      }

      &__wrapper {
        flex-basis: 60%;
      }
    }
  }
  @media (max-width: 767px) {
    .advantages {
      &__title {
        font-size: ${(props) => props.theme.text.title767Max};
      }

      &__img {
        height: 180px;
      }

      &__subtitle {
        margin-top: 15px;
        font-size: ${(props) => props.theme.text.description1099Max};
        color: ${(props) => props.theme.colors.markedText};
      }

      &__item {
        margin-top: 30px;
        display: block;
        text-align: center;
      }

      &__text {
        width: 80%;
        margin: 0 auto;
      }
    }
  }

  @media (max-width: 500px) {
    .advantages {
      &__text {
        width: 100%;
      }

      &__wrapper {
        flex-basis: 100%;
      }
    }
  }
`;

export const Advantages = ({ advantages }) => {
  return (
    <ThemeProvider theme={styleVariables}>
      <Section>
        <div className={classes.container}>
          <h2 className="advantages__title">{advantages.title}</h2>

          {advantages.advantages.map((advantage) => (
            <div className="advantages__item" key={advantage.id}>
              <img
                style={{ order: advantage.order }}
                src={require(`../../../images/images/advantages/${advantage.img}`)}
                alt={advantage.alt}
                className="advantages__img"
              />

              <div className="advantages__wrapper">
                <h3 className="advantages__subtitle">{advantage.title}</h3>

                <p className="advantages__text">{advantage.text}</p>
              </div>
            </div>
          ))}
        </div>
      </Section>
    </ThemeProvider>
  );
};

Advantages.propTypes = {
  advantages: PropTypes.shape({
    title: PropTypes.string,
    advantages: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};
