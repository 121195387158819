import React from "react";
import PropTypes from 'prop-types';
import classes from '../courses.module.scss';
import ReactPlayer from "react-player/lazy";
import { styleVariables } from "../../../theme/styleVariables";

import styled, { ThemeProvider } from "styled-components";
import {downloadFile} from "../../../utils/download-file";
import {iOS} from "../../../utils/is-iOS";

const Section = styled.section`
  position: relative;
  display: flex;
  padding-top: 85px;
  padding-bottom: 180px;
  max-width: 2000px;
  margin: 0 auto;

  background-image: ${`url(${require('../../../images/coursesBgHeader.png')})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .introduce {
    &__container {
      display: flex;
      justify-content: space-between;
    }

    &__img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 525px;
      height: 505px;
    }

    &__wrapper {
      flex-basis: 55%;
    }

    &__title {
      width: 65%;
      margin-top: 30px;
      font-style: normal;
      font-weight: bold;
      font-size: ${props => props.theme.text.pageTitle};
      line-height: 130%;
      color: ${props => props.theme.colors.markedText};
    }

    &__description {
      margin-top: 20px;
      font-size: ${props => props.theme.text.description};
      line-height: ${props => props.theme.text.descLineHide};

      color: ${props => props.theme.colors.text};
    }

    &__button {
      margin-top: 35px;
    }
  }
  .alternative_img {
    width: 100%;
  }

  @media (min-width: 2000px) {

    &::after {
      background-image: ${`url(${require('../../../images/coursesBgHeader.png')})`};
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      transform: scale(-1, 1);
      left: -87%;
    }

    &::before {
      background-image: ${`url(${require('../../../images/coursesBgHeader.png')})`};
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      transform: scale(-1, 1);;
      left: 99.5%;
    }
  }

  @media (min-width: 1600px) {

    .introduce {
      &__description {
        margin-top: 30px;
      }
      &__button {
        margin-top: 90px;
      }
    }
  }

  @media (max-width: 1599px) {

    .introduce {
      &__wrapper {
        flex-basis: 50%;
      }

      &__title {
        width: 100%;
      }
    }
  }

  @media (max-width: 1399px) {

    .introduce {
      &__img {
        width: 400px;
        height: 100%;
      }

      &__description {
        font-size: ${props => props.theme.text.description1399Max};
      }
    }
  }

  @media (max-width: 1099px) {
    padding-top: 30px;
    
    .introduce {
      &__img {
        width: 300px;
      }

      &__description {
        font-size: ${props => props.theme.text.description1099Max};
        line-height: ${props => props.theme.text.descLineHide1099Max};
      }

      &__title {
        font-size: ${props => props.theme.text.pageTitle1099Max};
      }
    }
  }

  @media (max-width: 767px) {
    padding-top: 40px;
    padding-bottom: 150px;
    .introduce {
      &__container {
        flex-direction: column;
        align-items: center;
      }

      &__wrapper {
        display:flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
      }

      &__description {
        font-size: ${props => props.theme.text.description400Max};
      }
    }
  }

  @media (max-width: 400px) {
    .introduce {
      &__img {
        width: 270px;
        height: 270px;
      }

      &__title {
        font-size: ${props => props.theme.text.pageTitle400Max};
      }
    }
  }
`;

export const Introduce = ({ introduce }) => {
  return (
    <ThemeProvider theme={ styleVariables }>
      <Section>
        <div className={`${ classes.container } introduce__container`}>
        
          <div className="introduce__img">
            { iOS()
              ? <img className="react-player alternative_img" src={introduce.altImg} alt="course-image"/>
              : <ReactPlayer
                  className="react-player introduce__img"
                  loop
                  playing
                  playsinline
                  muted
                  width='100%'
                  height='100%'
                  url= {introduce.img}
                  fileсonfig={{ attributes: { autoPlay: true } }}
                /> }
          </div>
          <div className="introduce__wrapper">
            <h2 className="introduce__title">
              { introduce.title }
            </h2>

            <p className="introduce__description">
              { introduce.description }
            </p>

            <button onClick={() => downloadFile(introduce.button.file, introduce.button.fileName)} className={`${ classes.button } introduce__button`}>
              { introduce.button.text }
            </button>
          </div>
        </div>
      </Section>
    </ThemeProvider>
  )
} 

Introduce.propTypes = {
  introduce: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    button: PropTypes.object,
    img: PropTypes.string,
  }).isRequired,
};
