import React from "react";
import PropTypes from "prop-types";
import classes from "../courses.module.scss";
import { styleVariables } from "../../../theme/styleVariables";
import styled, { ThemeProvider } from "styled-components";
import { ListOfRequirements } from "./ListOfRequirements";

const Section = styled.section`
  background-color: #fff;
  padding-bottom: 20px;

  .requirements {
    &__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__wrapper {
      flex-basis: 60%;
    }

    &__title {
      font-weight: bold;
      font-size: 36px;
      color: ${(props) => props.theme.colors.primaryColor};
    }

    &__img {
      display: flex;
      background-size: contain;
      background-repeat: no-repeat;
      width: 450px;
      height: 350px;
    }
  }

  @media (max-width: 1599px) {
    .requirements {
      &__wrapper {
        flex-basis: 45%;
      }
    }
  }

  @media (max-width: 1599px) {
    .requirements {
      &__img {
        width: 400px;
      }
    }
  }

  @media (max-width: 1099px) {
    .requirements {
      &__title {
        font-size: 26px;
      }

      &__img {
        width: 300px;
        height: 250px;
      }

      &__wrapper {
        flex-basis: 55%;
      }
    }
  }

  @media (max-width: 767px) {
    .requirements {
      &__container {
        flex-direction: column;
        align-items: center;
      }

      &__img {
        display: none;
      }
    }
  }
`;

export const Requirements = ({ requirements }) => {
  return (
    <ThemeProvider theme={styleVariables}>
      <Section>
        <div className={`${classes.container} requirements__container`}>
          <div className="requirements__wrapper">
            <h3 className="requirements__title">{requirements.title}</h3>

            <ListOfRequirements list={requirements.list} />
          </div>

          <div
            className="requirements__img"
            style={{
              backgroundImage: `url(${require(`../../../images/${requirements?.img}`)})`,
            }}
          />
        </div>
      </Section>
    </ThemeProvider>
  );
};

Requirements.propTypes = {
  requirements: PropTypes.shape({
    title: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.object),
    img: PropTypes.string,
  }).isRequired,
};
