import consultantPDF from '../../../Files/MS Dynamics 365 Consultant.pdf';
import consultantAltImg from '../../../images/images/Consult_ios.png';
import React from 'react';

export const consultant = {
  introducePage: {
    title: "Курс MS Dynamics 365 Consultant",
    description:
      "Якщо ти можеш вільно спілкуватися англійською, мрієш про те, щоб керувати проектами та любиш спілкувати з людьми, у тебе є можливість розпочати свою кар’єру в якості MS Dynamics CRM Consultant",
    button: {
      text: "Програма навчання",
      file: consultantPDF,
      fileName: 'MS Dynamics 365 Consultant.pdf',
    },
    img:
      "https://dynamicsnetuastorageprod.blob.core.windows.net/animations/Best_Consult.webm",
    altImg: consultantAltImg
  },

  requirementsPage: {
    title: "Основні вимоги:",
    list: [
      {
        id: 1,
        img: "cap.svg",
        alt: "cap",
        text: ", навчатися та працювати",
        textMarked: "Бажання розвиватися",
        markedFirst: true,
      },
      {
        id: 2,
        img: "bag.svg",
        alt: "business portfolio",
        text: "Круто, якщо ти маєш певний ",
        textMarked: "досвід роботи за фахом",
        markedFirst: false,
      },
      {
        id: 3,
        img: "diploma.svg",
        alt: "diploma",
        text: "з IT наук або економіки",
        textMarked: "Ступінь бакалавра ",
        markedFirst: true,
      },
      {
        id: 4,
        img: "language.svg",
        alt: "language",
        text: "(рівень не нижче Intermediate)",
        textMarked: "Навички спілкування англійською ",
        markedFirst: true,
      },
    ],
    img: "images/Ill_Certificate1.png",
  },

  recordingPage: {
    title: "Для того, щоб записатися на курс необхідно:",
    blocks: [
      {
        id: 1,
        img: "recording.png",
        alt: "recording",
        button: {
          title: "Зареєструватися",
          link: "",
        },
      },
    ],

    text: `Протягом 5-ти днів з тобою зв'яжуться, чекай на дзвінок та не забувай перевіряти свій email.`,
  },

  advantagesPage: {
    title: "Наші переваги:",
    advantages: [
      {
        id: 1,
        img: "time.png",
        alt: "time",
        title: "Тривалість навчання 2 тижня",
        text:
          "Навчання проходить щодня безпосередньо в київському офісі під керівництвом менторів з великим практичним досвідом у сфері впровадження Microsoft Dynamics 365 на європейському ринку.",
        order: 0,
      },
      {
        id: 2,
        img: "group.png",
        alt: "group of people",
        title: "Навчальна група до 6 чоловік",
        text:
          <>Кількість учнів на курсі обмежена, так як ми орієнтуємося на працевлаштування всіх успішних випускників курсу при компанії <b><a style={{ textDecoration: 'none' }} href='https://uds.systems/'>UDS Systems</a></b> на позицію Junior Dynamics 365 Consultant/Project Manager.</>,
        order: 1,
      },
      {
        id: 3,
        img: "bonus_free.png",
        alt: "free",
        title: "Вартість навчання — безкоштовно",
        text:
          "За час існування академії понад 50 випускників курсу MS Dynamics 365 Consultant безкоштовно отримали необхідні знання про систему та вдало продовжили свій шлях кар’єрою Microsoft Dynamics Consultant.",
        order: 0,
      },
    ],
  },
};
