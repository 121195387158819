import React, { useState } from "react";
import ReactDom from 'react-dom';
import { useForm } from "react-hook-form";
import { styleVariables } from "../../theme/styleVariables";
import classes from "../button.module.scss";
import classNames from 'classnames';
import Loader from 'react-loader-spinner'

import styled, { ThemeProvider }from "styled-components";
import emailjs from "emailjs-com";
import get from "lodash/get";
import { Link } from "react-router-dom";
import Media from "react-media";
import { YoutubeSearchedForSharp } from "@material-ui/icons";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 200;
`


const Form = styled.div`
  /* position: relative; */
  position: absolute;
  top: 150px;    
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 696px;
  padding-bottom: 85px;
  background-color: #fff;
  z-index: 201;

  &::after {
    content: '';
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    background-image: ${`url(${require('../../images/images/formBG.png')})`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 200px;
    height: 180px;
    z-index: 0;
  }

  * {
    box-sizing: border-box;
  }

  .form {
    position: relative;
    margin-top: 50px;
    display: flex;
    flex-direction: column;

    &_visibility {
      display: none;
    }

    &__loader {
      &_visibility {
        display: none;
      }
    }

    &__close {
      position: absolute;
      top: 19px;
      right: 17px;
      outline: none;
      border: none;
      background-color: ${(props) => props.theme.colors.primaryColor};
      width: 74px;
      height: 74px;
      border-radius: 50%;
      cursor: pointer;

      &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-image: ${`url(${require('../../images/icons/close.svg')})`};
        background-size: contain;
        background-repeat: no-repeat;
        width: 34%;
        height: 34%;
      }
    }

    &__container {
      max-width: 440px;
      z-index: 1;
    }

    &__title {
      margin-top: 80px;
      max-width: 438px;
      font-weight: bold;
      font-size: 24px;
      line-height: 31px;
      color: ${(props) => props.theme.colors.primaryColor};
      text-align: center;
    }

    &__label {
      margin: 10px 28px 0;
      font-size: 12px;
      line-height: 15px;
      text-transform: uppercase;
      color: ${(props) => props.theme.colors.markedText};
    }
    
    &__input {
      margin-top: 10px;
      padding: 15px 28px;
      border-radius: 70px;
      border: 1px solid #E4E4E4;
      font-size: 16px;
      color: ${(props) => props.theme.colors.markedText};
      outline: none;
      resize: none;

      &::placeholder {    
        color: #adadad;
      }

      &_error {
        color: #dd4727;
        border: 1px solid #dd4727;
      }

    }
    
    &__upload {
      position: relative;
      margin: 15px 28px 0;
      font-size: 14px;
      color: ${(props) => props.theme.colors.markedText};

      &::after {
        content: '';
        position: absolute;
        left: 200px;
        transform: translate(-50%, -25%);
        width: 30px;
        height: 30px;
        background-image: ${`url(${require('../../images/icons/upload.svg')})`};
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }

      &_hidden {
        visibility: hidden;
      }
      &_file {
        margin: 15px 28px 0;
        font-size: 14px;
        color: ${(props) => props.theme.colors.markedText};
        word-break: break-word;
      }
    }

    &__button {
      margin-top: 30px;
      font-size: 14px;
    }

    &__error_text {
      padding-left: 28px;
      font-size: 12px;
      color: #d82d08;

      &_display {
        visibility: hidden;
      }
    }

    &__description {
      margin-top: 22px;
      padding: 0 28px;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
    }
  }

  .leave {
    &__wrapper {
      margin-top: 72px;
      display: flex;
      justify-content: space-around;
      flex-basis: 50%;
    }

    &__button {
        height: 60px;
        padding: 0;
        flex-basis: 40%;
        font-size: 22px;

      &_accept {
        background: none;
        border: 2px solid #368FC5;
        color: ${(props) => props.theme.colors.primaryColor}; 
      }

      &_cancel {
        transition: 0s;
        &:hover {
          color: #fff;
          background: linear-gradient(180deg, #3697D1 0%, rgba(29, 73, 103, 0.29) 100%), #5689B4;
        }
      }
    }
  }

  @media (max-width: 900px) {
    width: 80%;
  }

  @media (max-width: 767px) {
    .form {
      &__close {
        width: 50px;
        height: 50px;
      }
    }
  }

  @media (max-width: 600px) {
    width: 90%;
    .form {
      &__title {
        font-size: 18px;
      }

      &__container {
        width: 85%;
      }
    }
  }

  /* @media (max-height: 1050px) {
    .form {
      &__title {
        font-size: 18px;
      }

      &__close {
        width: 50px;
        height: 50px;
      }
    }
  } */

  @media (max-width: 450px) {
    .form {
      &__close {
        width: 28px;
        height: 28px;
      }
    }
  }
`;

const FormNew = ({ toggleContactForm, content }) => {
  const { register, handleSubmit, errors } = useForm()
  const [ leaveForm, setLeaveForm ] = useState(false);
  const [ formWasSent, setFormWasSent ] = useState(false);
  const [ uploadedFiles, setUploadedFiles ] = useState([]);
  const [isSendingForm, setIsSendingForm] = useState(false);

  const onSubmit = (data, e) => {
    console.log(data)
  }

  const closeForm = () => {
    if (formWasSent) {
      toggleContactForm( false );
    } else {
      setLeaveForm(!leaveForm)
    }
  };

  function sendEmail(data, e) {
    e.preventDefault();
    setIsSendingForm(true);

    emailjs
      .sendForm(
        "gmail",
        content.template_id,
        e.target,
        "user_PjBk4AsJJeWCAU4tO0TDM"
      )
      .then(
        (result) => {
          console.log(result.text);
          setFormWasSent(true);
          // window.location.reload();
          setIsSendingForm(false);
        },
        (error) => {
          console.log(error.text);
          setIsSendingForm(false);
        }
      );
  }

  // const handleChange = (target) => {
  //   setFormData({...formData, value: target.value});
  // }

  return ReactDom.createPortal(
    <ThemeProvider theme={ styleVariables }>
      <Overlay onClick={closeForm}/>
      <Form>
        <div className="form__container">
          <button 
            className="form__close"
            onClick={closeForm}
          />
          <h3 className="form__title">
            { (!leaveForm) 
              ? !formWasSent
                ? `${content.title}`
                : 'Форма відправлена. Дякуємо за звернення!'
              : 'Ти впевнений, що хочеш залишити форму реєстрації?'
            }
          </h3>

          {!formWasSent && <form
            enctype="multipart/form-data"
            method="post"
            id="myform"
            className={classNames({
              form: true,
              form_visibility: leaveForm || isSendingForm,
            })} 
            onSubmit={handleSubmit(sendEmail)}
          >
            <label className="form__label" htmlFor="contactForm__name">ім’я</label>
            <input 
              className={classNames({
                form__input: true,
                form__input_error: errors.name,
              })} 
              id="contactForm__name"
              name="name"
              type="text"
              placeholder="Василь"
              ref={register({
                required: true,
                pattern: /^[a-zA-Z а-яА-ЯёЁ]+$/,
              })}
            />
            {errors.name && <p className="form__error_text">
              Поле заповнено невірно
            </p>}

            <label className="form__label" htmlFor="contactForm__email">email</label>
            <input 
              className={classNames({
                form__input: true,
                form__input_error: errors.email,
              })} 
              id="contactForm__email"
              name="email"
              type="email"
              placeholder="Vasulii@gmail.com"
              ref={register({
                required: true,
                pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
            />
            {errors.email && <p className="form__error_text">
              Поле заповнено невірно
            </p>}
            
            <label className="form__label" htmlFor="contactForm__phone">телефон</label>
            <input 
              className={classNames({
                form__input: true,
                form__input_error: errors.phone,
              })} 
              id="contactForm__phone" 
              name="phone"
              type="tel"
              title="099 999 9999"
              placeholder="099 076 3321"
              ref={register({
                required: true,
                pattern: /^[0-9 -()+]+$/,
              })}
            />
            {errors.phone && <p className="form__error_text">
              Поле заповнено невірно
            </p>}

            { content.message && 
              <>
                <label className="form__label" htmlFor="contactForm__message">повідомлення</label>
                <textarea
                  id="contactForm__message"
                  name="message"
                  ref={register({})}
                  className={classNames({
                    form__input: true,
                    form__input_error: errors.message,
                  })}
                  />
              </>
            }

            { content.description && <p className="form__description">
              Обов'язково вкажи у резюме посилання на хмарне сховище з виконаним ТЗ (Github, Google Drive, etc.)
            </p> }

            {content.upload &&
            <>
              <label className="form__upload" htmlFor="contactForm__upload">
                Upload CV: тільки PDF
                </label>
              <input 
                className="form__upload_hidden" 
                id="contactForm__upload" 
                name="file"
                onChange={(e) => setUploadedFiles(e.target.files)}
                type="file"
                title="upload file"
                accept=".pdf"
                ref={register}
              />
              {[].map.call(uploadedFiles, file => <span className="form__upload_file" key={file.name}>{file.name}</span>)}
            </>
            }

            <input 
              type="submit" 
              className={`${classes.button} 
              form__button`} 
              value={content.buttonText}
            />

          </form> }

            <Loader
              type="Oval"
              color="#3491C8"
              height={100}
              width={100}
              style={{display: !isSendingForm ? 'none' : 'flex',
              justifyContent: 'center',
              marginTop: '75px',}}
            />
          
          { leaveForm && <div className="leave__wrapper">
            <button 
              className={`
              ${classes.button} 
              leave__button
              leave__button_cancel
              `}
              onClick={closeForm}
            >
              Ні
            </button>

            <button 
              className={`
              ${classes.button}
              leave__button 
              leave__button_accept
              `}

              onClick={ () => toggleContactForm( false )}
            >
              так
            </button>
          </div> }
        </div>
      </Form>
    </ThemeProvider>,
    document.getElementById('form')
  );
};

export default FormNew;
