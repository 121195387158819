import React from "react";
import styled from "styled-components";

const ContactComponent = styled.div`
  display: flex;
  justify-content: space-around;
  position: relative;
  width: 100%;
  background: #fff;
  max-width: 1400px;
  padding: 0 0 60px 0;
  padding-top: ${(props) => ((!props.hideLine) ? '240px' : '70px')};

  @media (max-width: 1099px) {
    justify-content: space-around;
  }

  /* @media (max-width: 1200px) {
    flex-direction: column;
  } */

  @media (max-width: 1099px) {
    padding-top: ${(props) => ((!props.hideLine) ? '140px' : '70px')};
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }

  .info-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 0;

    @media (max-width: 1099px) {
      padding: 0 0 0 50px;
    }

    @media (max-width: 767px) {
      padding: 10px 0;
    }
  }

  .text-5 {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #09051f;

    @media (max-width: 1000px) {
      font-size: 16px !important;
    }

    @media (max-width: 768px) {
      font-size: 14px !important;
      text-align: center;
      width: 100% !important;
    }
  }

  .google-map {
    width: 670px;
    height: 440px;
    border: 2px solid #c1c9d0;
    border-radius: 25px;
    overflow: hidden;
    background-size: 120% !important;

    @media (max-width: 1400px) {
      width: 550px;
      height: 400px;
    }

    @media (max-width: 1099px) {
      width: 400px;
      height: 300px;
    }

    @media (max-width: 880px) {
      width: 350px;
      height: 270px;
    }

    /* @media (max-width: 1200px) {
      margin: 0 auto;
    } */

    @media (max-width: 700px) {
      width: 80%;
      height: 340px;
    }

    @media (max-width: 500px) {
      height: 240px;
    }
  }

  .our-contact {
    @media (max-width: 767px) {
      display: none;
    }
  }

  .our-contact-sm {
    display: none;
    @media (max-width: 767px) {
      display: flex;
      width: 100%;
      justify-content: center;
      padding: 0 0 20px 0;
    }

    @media (max-width: 600px) {
      padding: 0 0 40px 0;
    }
  }

  .img-contact {
    height: 40px;
  }
  .social-img {
    width: 35px;
  }

  .soc-box {
    @media (max-width: 600px) {
      margin: 15px 0 0 0;
    }
  }
  .text-6 {
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 48px;
    line-height: 59px;
    color: #3491c8;
    position: relative;
    z-index: 10;

    @media (max-width: 1099px) {
      font-size: 38px;
      line-height: 29px;
    }

    @media (max-width: 900px) {
      font-size: 34px;
    }

    @media (max-width: 767px) {
    }
    @media (max-width: 600px) {
      font-size: 24px;
      margin: 0 !important;
      line-height: 22px;
    }
  }

  .extra_wave {
    @media (max-width: 1399px) {
      display: none;
    }
  }
`;

const OurContactsComp = ({ hideLine, style }) => {
  return (
    <ContactComponent hideLine={ hideLine } style={style}>
      <div className="text-6 our-contact-sm">Наші контакти</div>
      <div
        className="google-map"
        style={{
          background: `url(${require("../images/map2.PNG")})no-repeat center`,
        }}
      />
      <div className="info-box">
        <div style={{ textAlign: "left" }} className="text-6 our-contact">
          Наші контакти
        </div>

        <div>
          <div
            style={{
              display: "flex",
              height: "60px",
              alignItems: "center",
            }}
            className="img-contact"
          >
            <img
              src={require("../images/Vector12.png")}
              alt="vector"
              style={{
                margin: "0 30px 0 0",
              }}
            />
            <div
              style={{ fontSize: "18px", textAlign: "left" }}
              className="text-5"
            >
              м. Київ, проспект Валерія Лобановського 150Б
            </div>
          </div>
          <div
            style={{
              display: "flex",
              height: "60px",
              alignItems: "center",
            }}
            className="img-contact"
          >
            <img
              src={require("../images/Vector13.png")}
              alt="vector"
              style={{
                margin: "0 30px 0 0",
              }}
            />
            <div
              style={{ fontSize: "18px", textAlign: "left" }}
              className="text-5"
            >
              Графік роботи: пн-пт 8:00-19:00
            </div>
          </div>
          <a
            style={{
              display: "flex",
              height: "60px",
              alignItems: "center",
              textDecoration: "none",
            }}
            className="img-contact"
            href="tel:+380968968811"
          >
            <img
              src={require("../images/Vector14.png")}
              alt="vector"
              style={{
                margin: "0 30px 0 0",
              }}
            />
            <div
              style={{ fontSize: "18px", textAlign: "left" }}
              className="text-5"
            >
              +38 (096) 896 88 11
            </div>
          </a>
        </div>
        <div style={{ display: "flex" }} className="soc-box">
          <a
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
            }}
            href="https://www.linkedin.com/company/uds-systems/"
          >
            <img
              className="social-img"
              src={require("../images/Vector15.png")}
              alt="vector"
            />
          </a>
          <a
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
              margin: "0 40px",
            }}
            href="https://www.instagram.com/uds.systems_office/"
          >
            <img
              className="social-img"
              src={require("../images/instagram.png")}
              alt="vector"
            />
          </a>
          <a
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
            }}
            href="https://www.facebook.com/UDS.systems/"
          >
            <img
              className="social-img"
              src={require("../images/Vector17.png")}
              alt="vector"
            />
          </a>
          <a
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
              margin: "0 0 0 40px",
            }}
            href="https://www.youtube.com/channel/UCx443BQ2U4gGXLPYB8Nu3bg"
          >
            <img
              className="social-img"
              src={require("../images/Vector18.png")}
              alt="vector"
            />
          </a>
        </div>
      </div>
      {!hideLine && (
        <>
          <img
            src={require("../images/Vector10.png")}
            alt="vector"
            style={{
              position: "absolute",
              top: "-40px",
              left: "50%",
              transform: "translateX(-50%)",
            }}
            className="line-6"
          />

          <img
            src={require("../images/Vector10.png")}
            alt="vector"
            style={{
              position: "absolute",
              top: "-29px",
              transform: "translateX(92%) scale(-1, 1)",
            }}
            className="extra_wave"
          />

          <img
            src={require("../images/Vector10.png")}
            alt="vector"
            style={{
              position: "absolute",
              top: "-34px",
              transform: "translateX(-81%) scale(-1, 1)",
            }}
            className="extra_wave"
          />
        </>
      )}
    </ContactComponent>
  );
};

export default OurContactsComp;
