import React, {useEffect, useState} from "react";
import { consultant } from './api/consultant'
import classes from './courses.module.scss';
import Header from "../Header";
import Footer from "../Footer";
import { Introduce } from "./components/Introduce";
import { Requirements } from "./components/Requirements";
import { Recording } from "./components/Recording";
import { Advantages } from "./components/Advantages";
import ContactForm from '../contactForm';
import { consultantForm } from '../contactForm/api';
import OurContactsComp from "../OurContactsComp";

const CRMConsultant = () => {
  const [ contactForm, setContactForm ] = useState(false);

  const toggleContactForm = (action) => {
    window.scrollTo(0, 0);
    setContactForm(action);
  }

  useEffect(() => {
    document.title = 'Consultant | Dynamics Education';
  }, []);

  return (
    <>
      <Header />
      
      { 
        contactForm && 
        <ContactForm
          toggleContactForm={toggleContactForm}
          content={ consultantForm }
        /> 
      }
      
      <main>
        <Introduce introduce={ consultant.introducePage }/>
        <Requirements requirements={ consultant.requirementsPage }/>
        <Recording 
          recording={ consultant.recordingPage }
          toggleContactForm={toggleContactForm}
        />
        <Advantages advantages={ consultant.advantagesPage }/>
        <div className={ classes.container }>
          <OurContactsComp hideLine={true}/>
        </div>
      </main>

      <Footer />
    </>
  );
  
};

export default CRMConsultant;
