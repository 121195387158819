import React from "react";
import styled from "styled-components";
import Header from "../Header";
import Footer from "../Footer";

const Blog = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  font-size: 18px;

  .blog-cont {
    width: 100%;
    max-width: 1400px;
  }

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 20px 0;
    color: #0067b8;
    font-size: 22px;
  }
`;

const BlogPage1 = () => {
  return (
    <>
      <Header />
      <div style={{ position: "relative" }}>
        <img
          height={300}
          src={require("../../images/teambuilding.jpg")}
          alt="img"
          style={{
            position: "absolute",
            zIndex: 1,
            left: "50%",
            transform: "translateX(-50%)",
          }}
        />
        <div
          style={{
            width: "100%",
            height: "300px",
            background: `url(${require("../../images/teambuilding.jpg")})`,
            filter: "grayscale(50%) blur(2px) opacity(50%)",
          }}
        />
      </div>
      <Blog>
        <div className="blog-cont">
          <div
            style={{
              display: "flex",
              width: "calc(100% - 40px)",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              background: "#f5f5f5",
              margin: "40px 0 20px 0",
              padding: "0 20px",
            }}
          >
            <h2
              className="title"
              style={{ margin: "40px 0 60px 0", fontSize: "26px" }}
            >
              Тимбилбинг. Идеи для воплощения
            </h2>
            <div style={{ width: "100%", lineHeight: 1.5 }}>
              <b>
                Тимбилдинг (“team”+”building” — построение команды) это
                английское слово как нельзя лучше передает смысл того, что
                зачастую нужно пройти в любой компании — построить команду.
                Активные вне офисные мероприятия, в которых люди должны
                содействовать друг другу, как нельзя лучше подходят для того,
                чтобы люди научились взаимодействовать, слышать, понимать
                другого человека/коллегу по цеху; и в неформальной сфере, через
                «игру» для взрослых — это сделать проще.
              </b>
              <br />
              <br />
              Для меня всегда было важно, чтобы людям было уютно в пределах
              офиса. Ведь мы проводим 1/3 нашей жизни на работе, живем именно
              здесь и сейчас, в этом пространстве и с этими людьми. Так почему
              бы не сделать это место интересным, увлекательным, комфортным и
              деятельным. Мы ведь склонны стремиться к тому, чтобы стать лучше.
              Вместе мы можем сделать больше. В коллективе люди вдохновляют друг
              друга, мотивируют, выступают примером (порой как позитивным, так и
              отрицательным), именно так и происходят изменения. <br />
              <br /> У каждого есть чему поучится, каждый имеет свои увлечения,
              хобби, список интересных мест, книг, фильмов и т.п., которыми
              может поделиться. <br />
              <br /> Самое занимательное начинается тогда, когда ты задаешь
              определённую волну и видишь, как она постепенно начинает набирать
              оборотов: люди сплочаются, начинают больше узнавать о друг друге,
              больше общаться не только в рамках офиса, но и вне работы. И это
              продуктивно влияет на деятельность самой компании. <br />
              <br /> Важно сохранить и продолжать поддерживать заданное
              направление, что зачастую бывает сложнее. В этом вопросе мне
              кажется важным систематичность и настойчивость, да у людей не
              всегда есть время на мероприятия, не всегда есть настроение,
              зачастую дома их кто-то или что-то ждёт и они спешат. И, не смотря
              на это, обязательно будет что-то интересное и близкое по душе
              человеку, что зацепит и привлечет внимание. Главное не
              останавливаться, продолжать предлагать.
            </div>
            <h3 className="title">Идеи для реализации</h3>
            <div style={{ width: "100%", lineHeight: 1.5 }}>
              Вот список идей для тимбилдинга компании «UDS Consulting», который
              мы с успехом реализуем. Наш коллектив насчитывает более 45
              сотрудников, включая удалённых работников. Ничего сложного или
              сверхъестественного, это то, что можно применить в любом
              коллективе. У каждой организации список будет отличаться, но
              данный набросок сориентирует в каком направлении двигаться
              конкретно в вашем коллективе. <br />
              <br /> <b>Квест-комнаты</b> <br />
              <br /> У нас в коллективе около 40% сотрудников вчерашние
              студенты. Тема с квест-комнатами в нашем случае прошла на ура. Мы
              неоднократно повторяли походы, ходили в квест-комнаты на различные
              тематики: по мотивам фильмов и книг, хоррор квест-комнаты, с
              актёрами и т.п. <br /> <br />
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  margin: "0 0 20px 0",
                }}
              >
                <img
                  style={{ width: "100%" }}
                  src={require("../../images/blog_images/01_Qy698H5.jpg")}
                  alt="img"
                />
              </div>
              И каждый раз это было интересно: менялись люди, задачи, ты учился
              быстро взаимодействовать в ограниченных условиях и при этом у
              каждого в процессе прохождения квеста проявлялись определённые
              черты: у кого-то лидерские, у кого-то аналитические, у кого-то
              умение сгруппировать других. Или же наоборот, было заметно, что
              какие-то непроработанные, уязвимые места, в человеке мешают
              выполнить задачу. Ты видишь это и понимаешь, в каком направлении
              нужно действовать дальше, как повысить ценность каждого сотрудника
              в компании, учитывая его слабые и сильные стороны.
            </div>
            <h3 className="title">Совместные походы в театр/кино</h3>
            <div style={{ width: "100%", lineHeight: 1.5 }}>
              Казалось бы, театр и айтишники — вещь несовместимая) но практика
              показала по-другому. Конечно, большинство предпочитает что-то
              легкое из театральных постановок, с юмором, но при этом интерес и
              готовность присоединится к культурному мероприятию есть. <br />
              <br /> К тому же всегда найдутся опытные театралы, которые могут
              поделиться своим опытом и сориентировать на какие постановки стоит
              сходить.
            </div>
            <h3 className="title">Рафтинг</h3>
            <div style={{ width: "100%", lineHeight: 1.5 }}>
              Идея с рафтингом поступила от одной из наших сотрудниц. Честно
              говоря, именно от нее мы не ожидали такого смелого предложения,
              так как она производила впечатление спокойного семейного человека,
              который не склонен к получению порции адреналина. И тут такое
              драйвовое мероприятие, с выездом в другой город, на целый день.
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  margin: "20px 0",
                }}
              >
                <img
                  style={{ width: "100%" }}
                  src={require("../../images/blog_images/03_a8TrhhX.jpg")}
                  alt="img"
                />
              </div>
              Мне казалось, что данная идея может не зайти, но попробовать очень
              хотелось. И, как обычно, в нашем общем чате было анонсировано
              данное предложение. В результате нас набралась команда из 10
              человек. Более того, к нам присоседились некоторые удалённые
              сотрудники. Это было неожиданно и вдохновляло на дальнейшие более
              масштабные мероприятия. Рафтинг мы провели в Мигеи, это был первый
              наш опыт, но думаем, что не последний.
            </div>
            <h3 className="title">Веревочный парк</h3>
            <div style={{ width: "100%", lineHeight: 1.5 }}>
              Когда на улице тёплая погода, один из занимательных вариантов
              тимбилдинга будет — испытать команду на выносливость, например, в
              верёвочном парке.
              <br />
              <br />
              Здесь нужно проявить определённое самообладание,
              сосредоточенность, физическую выносливость. В нашем случае, более
              выносливые коллеги мотивировали остальных на серьезную работу над
              собой. Ведь каждый хотел пройти этот путь до конца, показав при
              этом себе и остальным определённый результат.
              <br />
              <br />
              Физические упражнения и активность, это именно то, чего очень
              часто не хватает офисным работникам, которые целый день напролет
              проводят за мониторами компьютеров.
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  margin: "20px 0",
                }}
              >
                <img
                  style={{ width: "100%" }}
                  src={require("../../images/blog_images/06_c2338Vz.jpg")}
                  alt="img"
                />
              </div>
              <b> Zip-line </b> Захватывающий дух полет на высоте более 100
              метров, неспешный спуск без свободного падения и великолепные
              виды, позволяют в полной мере насладиться красотой и неповторимым
              изяществом окружающей природой. Ещё один вариантов проведения
              совместного интересного досуга.
            </div>
            <h3 className="title">Пейнтбол</h3>
            <div style={{ width: "100%", lineHeight: 1.5 }}>
              Думаю, у каждого на работе бывают ситуации, когда возникает
              желание немного «прибить» другого человека. Дать выплеск
              наболевшему. Пейнтбол — это как раз тот самый легальный вариант
              выплеснуть свои переживания и снова почувствовать командный дух.
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  margin: "20px 0",
                }}
              >
                <img
                  style={{ width: "100%" }}
                  src={require("../../images/blog_images/07_mZTr1KH.jpg")}
                  alt="img"
                />
              </div>
            </div>
            <h3 className="title">Музеи</h3>
            <div style={{ width: "100%", lineHeight: 1.5 }}>
              Поход в музей у нас был тематический, наша ИТ компания посетила
              «Software and Computer Museum» в Киеве{" "}
              <a href="https://sncmuseum.org/">sncmuseum.org</a> И хотя
              количество экспонатов у них небольшое и всё располагается в одном
              зале, гид провел нам увлекательную экскурсию. Впечатления от
              визита остались приятные, но пока что тему музеев мы больше не
              поднимали.
            </div>
            <h3 className="title">Картинг</h3>
            <div style={{ width: "100%", lineHeight: 1.5 }}>
              Также, как и в большинстве айтишных компаниях, мужчин в «UDS
              Consulting» больше чем женщин, соотношение примерно 65% к 35%.
              Поэтому картинг — это именно тот вариант, который многим пришелся
              по душе. С другой стороны, должна отметить, что в этом плане у нас
              девушки не особо уступали парням, которые так горели желанием
              победить и стать первыми во время заезда. Вследствие этого картинг
              стал для нас местом, которое мы посетили не один раз, в разных
              составах.
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  margin: "20px 0",
                }}
              >
                <img
                  src={require("../../images/blog_images/10_TefTapb.jpg")}
                  alt="img"
                />
              </div>
            </div>
            <h3 className="title">Мастер-классы</h3>
            <div style={{ width: "100%", lineHeight: 1.5 }}>
              Мастер-классы у нас в компании проходили по мыловарению и мехенди.
              При этом место проведение было непосредственно в самом офисе, что
              незначительно отрывало ребят от работы, но в тоже время была
              возможность переключиться и попробовать что-то новое.
              Мастер-классы проводили сотрудники, для которых это было хобби и
              то самое, чем они бы хотели и могут поделиться с сотрудниками.
              Достаточно было сброситься на материалы и вуаля — каждый приносит
              домой кусочек своего собственного креатива, сделанного на работе.
              <br />
              <br />
              Такие неформальные мероприятия сплочают и дают возможность узнать
              друг друга с новой стороны.
            </div>
            <h3 className="title">Каток</h3>
            <div style={{ width: "100%", lineHeight: 1.5 }}>
              Один из вариантов активного проведения совместного времени зимой,
              это покорение ледяных просторов на катке. Вне зависимости от того
              умеет ли человек кататься или же это в первый раз. Не умеешь —
              научим, умеешь — помогай другим осваивать, вообщем задача найдется
              каждому. А если падения, то только с улыбками и смехом.
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  margin: "20px 0",
                }}
              >
                <img
                  src={require("../../images/blog_images/14_GQozhpg.jpg")}
                  alt="img"
                />
              </div>
            </div>
            <h3 className="title">Настольные игры</h3>
            <div style={{ width: "100%", lineHeight: 1.5 }}>
              Здесь каждый сможет попробовать себя в новой роли. Мы практиковали
              вариант как офисных настолок, так и выездных, например, в клубе
              настольных игр «Гекс», где фанаты своего дела расскажут и покажут,
              что стоит попробовать и как именно в это играть.
              <br />
              <br />В процессе игры каждый участник переключается и зачастую
              начинает проявлять себя с новых сторон, ведь привычные офисные
              роли снимаются. Поэтому, если есть желание узнать своего коллегу
              получше, просто позови его поиграть в настолки.
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  margin: "20px 0",
                }}
              >
                <img
                  src={require("../../images/blog_images/16_1bw2mVq.jpg")}
                  alt="img"
                />
              </div>
              Если вы до сих пор задаетесь вопросом о том, как интересно и с
              пользой провести время после работы, просто воспользуйтесь этим
              списком. Ведь в нём каждый сможет найти что-то новое и
              увлекательное, то что зацепит других и вам просто захочется
              сделать это вместе.
            </div>

            <div style={{ width: "100%", margin: "20px 0", fontWeight: 500 }}>
              {" "}
              Мацёха Татьяна HR Generalist в UDS Consulting{" "}
              <a href="https://uds.systems/">uds.systems</a>
            </div>
          </div>
        </div>
      </Blog>
      <Footer />
    </>
  );
};

export default BlogPage1;
