import React, {useEffect, useState} from "react";
import { developer } from './api/developer'
import classes from './courses.module.scss';

import Header from "../Header";
import Footer from "../Footer";
import OurContactsComp from "../OurContactsComp";

import { Introduce } from "./components/Introduce";
import { Requirements } from "./components/Requirements";
import { Recording } from "./components/Recording";
import { Advantages } from "./components/Advantages";
import ContactForm from '../contactForm';
import { developerForm } from '../contactForm/api';

const CRMDeveloper = () => {
  const [ contactForm, setContactForm ] = useState(false);

  const toggleContactForm = (action) => {
    window.scrollTo(0, 0);
    setContactForm(action);
  }

  useEffect(() => {
    document.title = 'Developer  | Dynamics Education';
  }, []);

  return (
    <>
      <Header primary={true}/>

      { 
        contactForm && 
        <ContactForm
          toggleContactForm={toggleContactForm}
          content={ developerForm }
        /> 
      }

      <main>
        <Introduce introduce={ developer.introducePage }/>
        <Requirements requirements={ developer.requirementsPage }/>
        <Recording 
          recording={ developer.recordingPage }
          toggleContactForm={toggleContactForm}
        />
        <Advantages advantages={ developer.advantagesPage }/>
        <div className={ classes.container }>
          <OurContactsComp hideLine={true}/>
        </div>
      </main>


      <Footer />
      
    </>
  );
};

export default CRMDeveloper;
